import React, { Component } from 'react'
import Layout from '@components/layout'
import SocialNetworks from '@components/social-networks'

function isMobile() {
  return document.body.clientWidth <= 770
}

function getLocations() {
  return document.querySelectorAll(
    '.contact-map__location .contact-map__content'
  )
}

function getLocation(locationId) {
  return document.querySelector(`#${locationId} .contact-map__content`)
}

export default class ContactUs extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    isMobile()
      ? this.prepareForMobile.bind(this)()
      : this.prepareForDesktop.bind(this)()
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  submitForm(e) {
    e.preventDefault()
  }

  handleClickOutsideLocation(e) {
    const location = Array.from(getLocations()).filter(
      (elem) => elem.style.display === 'flex'
    )[0]

    if (!location) {
      return
    } else if (!location.parentElement.contains(e.target)) {
      location.style = 'display: none'
    }
  }

  handleResize() {
    if (!this.state.mobile && isMobile()) {
      this.prepareForMobile.bind(this)()
    }

    if (this.state.mobile && !isMobile()) {
      this.prepareForDesktop.bind(this)()
    }
  }

  prepareForMobile() {
    getLocations().forEach((elem) => (elem.style = 'display: none'))
    window.addEventListener(
      'click',
      this.handleClickOutsideLocation.bind(this)
    )
    return this.setState({ mobile: true })
  }

  prepareForDesktop() {
    getLocations().forEach((elem) => (elem.style = ''))
    window.removeEventListener(
      'click',
      this.handleClickOutsideLocation.bind(this)
    )
    this.setState({ mobile: false })
  }

  toggleLocation(location) {
    if (!this.state.mobile) {
      return
    }

    getLocations().forEach((elem) => (elem.style = 'display: none'))
    getLocation(location).style = 'display: flex'
  }

  render() {
    return (
      <Layout location={this.props.location} title='Contact Us' namespace='contact_us' noFooterBanner>
        <div className='hero hero-1 contact-hero'>
          <div className='hero-1__col-1'>
            <div className='title-label-1'>
              <div className='title-label-1__count'>
                05
              </div>
              <div className='title-label-1__seperator'></div>
              <div className='title-label-1__label'>
                Contact Us
              </div>
            </div>
            <h1 className='hero-1__title'>
              Let's Get In Touch
            </h1>
            <div className='hero-1__content'>
              <div className='hero-1__email-container'>
                <div className='hero-1__email-icon'>
                  <div className='icon-email-icon'></div>
                </div>
                <a className='hero-1__email' href='mailto: hello@wyeworks.com'>
                  hello@wyeworks.com
                </a>
              </div>
              <div className='hero-1__phone-container'>
                <div className='hero-1__phone-icon'>
                  <div className='icon-phone-icon'></div>
                </div>
                <div className='hero-1__phone' title="Uruguay">
                  +598 2622 5895 (🇺🇾)
                </div>
              </div>
              <div className='hero-1__phone-container'>
                <div className='hero-1__phone-icon'>
                  <div className='icon-phone-icon'></div>
                </div>
                <div className='hero-1__phone' title="Spain">
                  +34 635 12 61 82 (🇪🇸)
                </div>
              </div>
              <SocialNetworks wrapperClassList='nav__social social-layout-1--alt' linkClassList='social-layout-1__icon--alt'>
                <div className='social-layout-1__seperator social-layout-1__seperator--alt'></div>
                <div className='social-layout-1__follow-us social-layout-1__follow-us--alt'>
                  Follow Us
                </div>
              </SocialNetworks>
            </div>
          </div>
          <div className='hero-1__hero-image hero-1__hero-image--contact'>
            <div className='hero-1__image-wrapper'>
              <div className='hero-1__image hero-1__image--contact'>
                <div className='contact-form'>
                  <div className='contact-form__container'>
                    <form
                      method="post"
                      name='contact'
                      data-netlify='true'
                      netlify-honeypot="this-is-for-you-bot"
                    >
                      <input type='hidden' name='form-name' value='contact' />

                      <p style={{display: 'none'}}>
                        <label>Don’t fill this out if you're human: <input name="this-is-for-you-bot" /></label>
                      </p>

                      <div className='form__row'>
                        <fieldset>
                          <input id="full_name" name='full_name' autoComplete='off' placeholder='' required='' type='text'/>
                          <label htmlFor='full_name'>
                            Full Name
                          </label>
                        </fieldset>
                      </div>
                      <div className='form__row'>
                        <fieldset>
                          <input id='email' name='email' autoComplete='off' placeholder='' required='' type='text' />
                          <label htmlFor='email'>
                            Email Address
                          </label>
                        </fieldset>
                      </div>
                      <div className='form__row'>
                        <fieldset>
                          <input id='phone' name='phone' autoComplete='off' placeholder='' required='' type='text' />
                          <label htmlFor='phone'>
                            Phone Number
                          </label>
                        </fieldset>
                      </div>
                      <div className='form__row'>
                        <fieldset>
                          <input id='company' name='company' autoComplete='off' placeholder='' required='' type='text' />
                          <label htmlFor='company'>
                            Company Name
                          </label>
                        </fieldset>
                      </div>
                      <div className='form__row'>
                        <fieldset>
                          <input id='location' name='location' autoComplete='off' placeholder='' required='' type='text' />
                          <label htmlFor='location'>
                            Location
                          </label>
                        </fieldset>
                        <fieldset>
                          <input id='budget' name='budget' autoComplete='off' placeholder='' required='' type='text' />
                          <label htmlFor='budget'>
                            Budget
                          </label>
                        </fieldset>
                      </div>
                      <div className='form__row text-area'>
                        <fieldset>
                          <textarea id='message' cols='105' name='message' autoComplete='off' placeholder='' required='' rows='1' wrap='soft'></textarea>
                          <label htmlFor='message'>
                            Message
                          </label>
                        </fieldset>
                      </div>
                      <div className='form__row'>
                        <fieldset className='submit-button'>
                          <button name='submit' type='submit'>
                            Submit Form
                          </button>
                        </fieldset>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='hero-1__col-2'></div>
        </div>
        <div className='contact-map__bg'>
          <h4 className='contact-map__header'>Our Headquarters</h4>
          <div className='contact-map__location-container'>
            <img src='/images/map.png' />
            <div className='contact-map__location' id='montevideo'>
              <button
                className='contact-map__pin'
                onClick={() => this.toggleLocation.bind(this)('montevideo')}
              />
              <div className='contact-map__content'>
                <h5 className='contact-map__city'>Montevideo</h5>
                <p className='contact-map__address'>
                  Solano García 2470, 11300 Montevideo, Uruguay
                </p>
              </div>
            </div>
            <div className='contact-map__location' id='barcelona'>
              <button
                className='contact-map__pin'
                onClick={() => this.toggleLocation.bind(this)('barcelona')}
              />
              <div className='contact-map__content'>
                <h5 className='contact-map__city'>Barcelona</h5>
                <p className='contact-map__address'>
                  Norrsken, Passeig del Mare Nostrum, 15, Ciutat Vella, 08039
                  Barcelona, España
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
